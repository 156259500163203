import { createAsyncThunk } from '@reduxjs/toolkit';
import {usersApi} from "../api/users-api";

export const getUsersThunk = createAsyncThunk(
  'get-users',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await usersApi.getUsers(values);
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getUserThunk = createAsyncThunk(
  'get-user',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await usersApi.getUser(id);
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const createUserThunk = createAsyncThunk(
  'create-user',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await usersApi.createUser(values);
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const updateUserThunk = createAsyncThunk(
  'update-user',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const {id, userData} = values;
      const { data } = await usersApi.updateUser(id, userData);
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const deleteUserThunk = createAsyncThunk(
  'delete-user',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await usersApi.deleteUser(id);
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const exportUsersThunk = createAsyncThunk(
  'export-users',
  async (values: any | {} | null ,thunkAPI) => {
    try {
      const { data } = await usersApi.exportUsers();
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
