import { createSlice } from '@reduxjs/toolkit';
import {createUserThunk, getUsersThunk, getUserThunk, updateUserThunk} from "../actions/usersActions";

export type UsersType = {
  users: ItemsType;
  user: UserType;
};

export type ItemsType = {
  items: Array<UserType>;
  totalItems: number;
  itemsPerPage: number;
  isLoading: boolean
}

export type UserType = {
  data: UserDataType,
  isLoading: boolean,
  errors: any
};

export type UserDataType = {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  employment_status: string;
  where_studying: string;
  email_updates: boolean;
  is_admin: boolean;
};

const defaultState: UsersType = {
  users: {
    items: [] as Array<UserType>,
    totalItems: 0,
    itemsPerPage: 10,
    isLoading: false
  },
  user: {
    data: {} as UserDataType,
    errors: {},
    isLoading: false
  } as UserType
};

const usersSlice = createSlice({
  name: 'home',
  initialState: defaultState,
  reducers: {
    clearUser: (state) => {
      state.user.data = defaultState.user.data;
      state.user.errors = defaultState.user.errors;
    }
  },
  extraReducers: {
    [getUsersThunk.pending.toString()]: (state, { payload }) => {
      state.users.isLoading = true;
    },
    [getUsersThunk.fulfilled.toString()]: (state, { payload }) => {
      state.users = {...payload};
      state.users.isLoading = false;
    },
    [getUserThunk.fulfilled.toString()]: (state, { payload }) => {
      state.user.data = {...payload};
    },
    [createUserThunk.fulfilled.toString()]: (state, { payload }) => {
      state.user.data = {...payload};
      state.user.errors = null;
    },
    [createUserThunk.rejected.toString()]: (state, { payload }) => {
      state.user.errors = payload;
    },
    [updateUserThunk.fulfilled.toString()]: (state, { payload }) => {
      state.user.data = { ...state.user.data, ...payload };
      state.user.errors = null;
    },
    [updateUserThunk.rejected.toString()]: (state, { payload }) => {
      state.user.errors = payload;
    },
  },
});

const usersReducer = usersSlice.reducer;

export const {clearUser} = usersSlice.actions;
export default usersReducer;