import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const  AdminRoute = (props:any) => {
  const userSessionStorage = localStorage.getItem('userSession');
  const userSession = userSessionStorage?JSON.parse(userSessionStorage):null;
  return userSession?.user?.is_admin?
    (<Route  {...props} />) :
    (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />);
};
export  { AdminRoute };