import {
  instance,
  ResultCodesEnum,
  ResponseType,
} from './api';

type LoginResponseType = {
  message: string;
};

export const authAPI = {
  login(
    email: string,
    password: string,
    //rememberMe = false
  ) {
    return instance.post<ResponseType<LoginResponseType,
      ResultCodesEnum>>('login', {email, password});
  },
  logout() {
    return instance.delete(`auth/login`);
  }
};
