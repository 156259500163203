import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authAPI } from '../api/auth-api';
import { usersApi } from '../api/users-api';
import { alertShowThunk } from './Alert';
import { DispatchType } from './store';

export type SidebarType = {
  sidebarShow: any;
};

// @ts-ignore
let sidebarShow = localStorage.getItem('sidebarShow');

const defaultState: SidebarType = {
  sidebarShow: sidebarShow?sidebarShow:'responsive',
};

const sidebarSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    setShowSidebar: (state, action: PayloadAction<any>) => {
      state.sidebarShow = action.payload;
      localStorage.setItem('sidebarShow', state.sidebarShow);
    }
  },
});

const sidebarReducer = sidebarSlice.reducer;

export const {
  setShowSidebar
} = sidebarSlice.actions;
export default sidebarReducer;
