import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createBookThunk, getBooksThunk, getBookThunk, updateBookThunk} from "../actions/booksActions";

export type BookDataType = {
  id: number;
  name: string;
  description: string;
  image: string;
  author: string;
  pages_count: number;
  source: string;
  is_published: boolean;
};

export type BookType = {
  data: BookDataType,
  isLoading: boolean,
  errors: any
};

export type ItemsType = {
  items: Array<BookType>;
  totalItems: number;
  itemsPerPage: number;
  isLoading: boolean
}

type BooksStateType = {
  books: ItemsType;
  book: BookType;
};

const defaultState: BooksStateType = {
  books: {
    items: [] as Array<BookType>,
    totalItems: 0,
    itemsPerPage: 10,
    isLoading: false
  },
  book: {
    data: {} as BookDataType,
    errors: {},
    isLoading: false
  } as BookType
};

const booksSlice = createSlice({
  name: 'home',
  initialState: defaultState,
  reducers: {
    clearBook: (state) => {
      state.book.data = defaultState.book.data;
      state.book.errors = {};
    }
  },
  extraReducers: {
    [getBooksThunk.pending.toString()]: (state, { payload }) => {
      state.books.isLoading = true;
    },
    [getBooksThunk.fulfilled.toString()]: (state, { payload }) => {
      state.books = {...payload};
      state.books.isLoading = false;
    },
    [getBookThunk.fulfilled.toString()]: (state, { payload }) => {
      state.book.data = {...payload};
    },
    [createBookThunk.fulfilled.toString()]: (state, { payload }) => {
      state.book.data = {...payload};
      state.book.errors = null;
    },
    [createBookThunk.rejected.toString()]: (state, { payload }) => {
      state.book.errors = payload;
    },
    [updateBookThunk.fulfilled.toString()]: (state, { payload }) => {
      state.book.data = { ...state.book.data, ...payload };
      state.book.errors = null;
    },
    [updateBookThunk.rejected.toString()]: (state, { payload }) => {
      state.book.errors = payload;
    },
  },
});

const booksReducer = booksSlice.reducer;

export const {clearBook} = booksSlice.actions;
export default booksReducer;
