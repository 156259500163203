import { createAsyncThunk } from '@reduxjs/toolkit';
import {booksApi} from "../api/books-api";

export const getBooksThunk = createAsyncThunk(
  'get-books',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await booksApi.getBooks(values);
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getBookThunk = createAsyncThunk(
  'get-book',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await booksApi.getBook(id);
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const createBookThunk = createAsyncThunk(
  'create-book',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await booksApi.createBook(values);
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const updateBookThunk = createAsyncThunk(
  'update-book',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const {id, bookData} = values;
      const { data } = await booksApi.updateBook(id, bookData);
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const deleteBookThunk = createAsyncThunk(
  'delete-book',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await booksApi.deleteBook(id);
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

