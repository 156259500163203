import { createSlice } from '@reduxjs/toolkit';
import { getStatsThunk } from '../actions/dashboardActions';

export type DashboardType = {
  stats: StatsType
};

export type StatsType = {
  usersCount: number;
  subscribersCount: number;
  booksCount: number;
};

const defaultState: DashboardType = {
  stats: {} as StatsType
};

const dashboardSlice = createSlice({
  name: 'home',
  initialState: defaultState,
  reducers: {},
  extraReducers: {
    [getStatsThunk.fulfilled.toString()]: (state, { payload }) => {
      state.stats.usersCount = payload.usersCount;
      state.stats.subscribersCount = payload.subscribersCount;
      state.stats.booksCount = payload.booksCount;
    },
  },
});

const dashboardReducer = dashboardSlice.reducer;

export const {} = dashboardSlice.actions;
export default dashboardReducer;