import axios from 'axios';
import {logoutThunk} from "../redux/Auth";
import {alertShowThunk} from "../redux/Alert";
import store from '../redux/store';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(function (config) {
  // @ts-ignore
  const userSession = JSON.parse(localStorage.getItem('userSession'))
  if(userSession){
    // @ts-ignore
    config.headers.Authorization =  `Bearer ${userSession.access_token}`;
  }
  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
},
  function (error) {
    if(error.response.status === 401){
      //@ts-ignore
      store.dispatch(logoutThunk());
      store.dispatch(alertShowThunk('error', 'You need to be authorized to perform this action'));
    }
    return Promise.reject(error);
});

export enum ResultCodesEnum {
  Success = 200,
  Error = 401,
}
export enum ResultCodeForCaptchaEnum {
  CaptchaIsRequired = 10,
}

export type ResponseType<D = {}, RC = ResultCodesEnum> = {
  data: D;
  messages: Array<string>;
  resultCode: RC;
};
