import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from './Auth';
import profileReducer from './Profile';
import alertReducer from "./Alert";
import booksReducer from "./Books";
import dashboardReducer from "./Dashboard";
import usersReducer from "./Users";
import sidebarReducer from "./Sidebar";

const middleware = [...getDefaultMiddleware({serializableCheck: false})];

export type StateType = ReturnType<typeof store.getState>;
export type DispatchType = typeof store.dispatch;

const store = configureStore({
  reducer: {
    books: booksReducer,
    auth: authReducer,
    profile: profileReducer,
    alert: alertReducer,
    dashboard: dashboardReducer,
    users: usersReducer,
    sidebar: sidebarReducer,
  },
  middleware,
});
export default store;
