import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createUserThunk, updateUserThunk, deleteUserThunk} from "../actions/usersActions";
import {createBookThunk, deleteBookThunk, updateBookThunk} from "../actions/booksActions";

export type AlertType = {
  open: boolean;
  type: string;
  message: string;
  length?: number;
};

const defaultState: AlertType = {
  open: false,
  type: 'success',
  message: '',
  length: 5000,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState: defaultState,
  reducers: {
    setAlert: (state, { payload }) => {
      state.open = true;
      state.type = payload.type;
      state.message = payload.message;
      state.length = +payload.time >= 0 ? payload.time : 5000;
    },
    closeAlert: (state) => {
      state.open = false;
      state.message = '';
    },
  },
  extraReducers: {
    [createUserThunk.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'success';
      state.message = 'User successfully created';
    },
    [updateUserThunk.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'success';
      state.message = 'User successfully updated';
    },
    [deleteUserThunk.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'success';
      state.message = 'User successfully deleted';
    },
    [createBookThunk.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'success';
      state.message = 'Book successfully created';
    },
    [updateBookThunk.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'success';
      state.message = 'Book successfully updated';
    },
    [deleteBookThunk.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'success';
      state.message = 'Book successfully deleted';
    },
  },
});

const alertReducer = alertSlice.reducer;

export const { setAlert, closeAlert } = alertSlice.actions;
export default alertReducer;

export const alertShowThunk = (type: string, message: string, time?: number): any => async (dispatch: any) => {
  const alertData = {
    type: type,
    message: message,
    time,
  };
  dispatch(setAlert(alertData));
};