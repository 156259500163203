import {
  instance,
} from './api';

export type BooksQueryType = {
  page: number;
}

export const booksApi = {
  getBooks: async (values: BooksQueryType) => {
    const { data } = await instance.get(`books`, {
      params: values
    });
    return data;
  },
  getBook: async (id: number) => {
    const { data } = await instance.get(`books/${id}`);
    return data;
  },
  createBook: async (bookData:any) => {
    const { data } = await instance.post(`books`, bookData, {
      headers: {
        'Content-Type': 'multipart/form-data' //application/x-www-form-urlencoded | multipart/form-data
      }
    });
    return data;
  },
  updateBook: async (id:number, bookData:any) => {
    const { data } = await instance.post(`books/${id}`, bookData, { // ToDo: Used POST instead of PUT here because of PHP/Laravel issue - https://github.com/laravel/framework/issues/13457
      headers: {
        'Content-Type': 'multipart/form-data' //application/x-www-form-urlencoded | multipart/form-data
      }
    });
    return data;
  },
  deleteBook: async (id: number) => {
    const { data } = await instance.delete(`books/${id}`);
    return data;
  },
};
