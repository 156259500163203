import {
  instance,
} from './api';

export const dashboardAPI = {
  getStats: async () => {
    const { data } = await instance.get(`dashboard`);
    return data;
  }
};
