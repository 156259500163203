import { createAsyncThunk } from '@reduxjs/toolkit';
import {dashboardAPI} from "../api/dashboard-api";

export const getStatsThunk = createAsyncThunk(
  'get-stats',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await dashboardAPI.getStats();
      return data;
    } catch (err) {
      //@ts-ignore
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
