import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileState {}

const defaultState: ProfileState = {};

const profileSlice = createSlice({
  name: 'profile',
  initialState: defaultState,
  reducers: {
    setProfile: (state, action: PayloadAction<any>) => {
      state = action.payload;
    },
  },
});

const profileReducer = profileSlice.reducer;

export const { setProfile } = profileSlice.actions;
export default profileReducer;
