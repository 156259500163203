import { instance } from './api';
import {UserType} from "../redux/Users";

export type UsersQueryType = {
  page: number;
}

export const usersApi = {
  getUsers: async (values: UsersQueryType) => {
    const { data } = await instance.get(`users`, {
      params: values
    });
    return data;
  },
  getUser: async (id: number) => {
    const { data } = await instance.get(`users/${id}`);
    return data;
  },
  createUser: async (userData:any) => {
    const { data } = await instance.post(`users`, userData);
    return data;
  },
  updateUser: async (id:number, userData:any) => {
    const { data } = await instance.put(`users/${id}`, userData);
    return data;
  },

  deleteUser: async (id: number) => {
    const { data } = await instance.delete(`users/${id}`);
    return data;
  },
  exportUsers: async () => {
    return await instance.get(`users/export`, {responseType: 'blob'});
  },
};
