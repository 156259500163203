import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authAPI } from '../api/auth-api';
import { usersApi } from '../api/users-api';
import { alertShowThunk } from './Alert';
import { DispatchType } from './store';

export type UserType = {
  id: number;
  firstname: string;
  lastname: string;
  where_studying: string;
  updated_at: string;
  email: string;
  email_verified_at: string | null;
  is_admin: boolean;
  created_at: string;
};

type AuthStateType = {
  login: any;
  userSession: UserSessionType;
  validation: any;
};

export type UserSessionType = {
  access_token: string;
  expires_in: number;
  token_type: string;
  user: UserType;
};

type AuthError = {
  isError: boolean;
  message: string;
};

// @ts-ignore
let userSession = JSON.parse(localStorage.getItem('userSession'));

const defaultState: AuthStateType = {
  userSession: userSession,
  login: {},
  validation: {
    isError: false,
    errors: {
      email: '',
      password: '',
      credentials: '',
    },
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    //@ts-ignore
    setUser: (state, action: PayloadAction<any>) => {
      state.userSession = action.payload.data;
      localStorage.setItem('userSession', JSON.stringify(state.userSession));
    },
    profile: (state, action: PayloadAction<any>) => {
      state.userSession = action.payload.data;
    },
    authError: (state, action: PayloadAction<any>) => {
      state.validation.isError = true;
      state.validation.errors = action.payload.data;
    },
    logoutUser: (state) => {
      localStorage.removeItem('userSession');
      state.userSession = {} as UserSessionType;
    },
  },
});

const authReducer = authSlice.reducer;

export const {
  setUser,
  profile,
  authError,
  logoutUser,
} = authSlice.actions;
export default authReducer;

export const loginThunk = (email: string, password: string) => async (
  dispatch: DispatchType
) => {
  try {
    const authDetails = await authAPI.login(email, password);
    dispatch(setUser(authDetails));
  } catch (err) {
    //@ts-ignore
    dispatch(authError(err.response));
  }
};

// export const profileThunk = () => async (dispatch: DispatchType) => {
//   try {
//     const userDetails = await usersApi.profile();
//     dispatch(profile(userDetails));
//   } catch (err) {
//     dispatch(profile(err.response));
//   }
// };

export const logoutThunk = () => async (dispatch: DispatchType) => {
  dispatch(logoutUser());
};
